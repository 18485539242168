<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">Fornecedores</h3>
    </div>
    <div class="card-body">
      <table class="table table-head-bg-primary">
        <thead>
        <tr>
          <th scope="col">id</th>
          <th class="text-center" scope="col">Nome Fantasia</th>
          <th class="text-center" scope="col">Razão Social</th>
          <th class="text-center" scope="col">CNPJ</th>
          <th class="text-center" scope="col">Telefone 1</th>
          <th class="text-center" scope="col">E-mail</th>
          <th class="text-center" scope="col">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(lista, index) in lista_fornecedor"
            :key="index"
        >
          <td>{{ lista.id }}</td>
          <td class="text-center">
            {{ lista.nome_fantasia }}
          </td>
          <td class="text-center">
            {{ lista.razao_social }}
          </td>

          <td class="text-center">
            {{ lista.cnpj }}
          </td>
          <td class="text-center">
            {{ lista.telefone1 }}
          </td>
          <td class="text-center">
            {{ lista.email }}
          </td>


          <td class="text-center">
            <button
                v-show="lista_permissoes_filial.u_Fornecedor"
                @click="atualizar(lista)"
                class="btn btn-sm btn-icon btn-bg-primary btn-icon-light btn-hover-light"
            >
              <i class="flaticon-edit-1"></i>
            </button>

            <button
                v-show="lista_permissoes_filial.lock_Fornecedor"
                @click="confirm(lista)"
                class="ml-1 btn btn-sm btn-icon btn-bg-danger btn-icon-light btn-hover-light"
            >
              <i class="flaticon-lock"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Fornecedor"}]);
  },
  computed: {
    lista_fornecedor() {
      return this.$store.state.financeiro.lista_fornecedor;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_fornecedor();
  },
  methods: {
    async listar_fornecedor() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("financeiro/listar_fornecedor").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createFornecedor"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara essa fornecedor no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("financeiro/bloquear_fornecedor", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>